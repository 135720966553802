import { UniDealerApiC } from '@unikey/unikey-commons/release/comm'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'uni';
export const environment: string = 'staging';
export const isDevelopmentEnv: boolean = environment === 'development';
export const isTesting: boolean = '' as string === 'true'

const commNonceEndpoint = { nonceEndpoint: 'https://uni-stg-dealer-api.unikey.com/v5/nonces' };
export const api: UniDealerApiC = new UniDealerApiC('https://uni-stg-dealer-api.unikey.com', 'v5', 'uni'.toUpperCase(), { enableLogging: isDevelopmentEnv, isTesting, ...commNonceEndpoint })
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', 'a47500d4-6ae2-4105-a97f-9ae243b8efd1', 'YgBlADMAMABkAGIANwAwAC0AOQA3AGMAMgAtADQANwAwADkALQBiADIAMgA4AC0AZgBkAGQAZAA3ADcAOABjAGMAMQAxADYA', { enableLogging: isDevelopmentEnv && false, isTesting, ...commNonceEndpoint });

export const captchaSiteKey: string = '6LdREKoUAAAAAB8tBnqwOxUkcUTMOkP1jJwzWWve';
export const captchaSecretKey: string = '6LdREKoUAAAAACJUb2vMHricHwQ2HW4YKqPxA2bo';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://www.unikey.com/lets-connect/';
export const deeplinkDomain = 'https://uni-stg-dealer-web.unikey.com'

export const appInsightsInstrumentationKey: string = '46da23b9-d599-46ec-97bc-2440fe60f7ff';
export const mockAuth: string = '';

export const iosAppStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/BlueKey-Staging';
export const googlePlayStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/BlueKey-Staging-1';

export const oidcScope: string = 'openid email profile comm.dealer.api identity.api offline_access';
export const oidcClientId: string = 'a47500d4-6ae2-4105-a97f-9ae243b8efd1';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://staging.bluekey.io/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = 'YgBlADMAMABkAGIANwAwAC0AOQA3AGMAMgAtADQANwAwADkALQBiADIAMgA4AC0AZgBkAGQAZAA3ADcAOABjAGMAMQAxADYA';
export const oidcPartnerBrandId: string = '87ccc2dd-6a00-49cb-bdba-255a26dae171';
export const oidcPartnerBrandCode: string = 'UNI';
export const oidcSilentRedirectUri: string = 'https://staging.bluekey.io/#/token';
export const oidcForgotPasswordRedirect: string = '';

export const buildId: string = '40975';
export const releaseId: string = 'Release-283';
export const cdpVersion: string = '6.0.0';
export const commonsVersion: string = '8.0.4';
export const desiredLocales: string = 'en,es,ja,fr,pt';